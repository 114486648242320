import { ReactComponent as SvgCircle } from './icons/l-circle.svg'
import { css, keyframes } from '@emotion/core'
import styled from '@emotion/styled'

const spin = keyframes`
  0%  { transform: rotate(0deg); }
  100% { transform: rotate(359deg); }  
`

const Spinner = styled(SvgCircle)`
  animation-name: ${spin};
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`

export default Spinner
