import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import React, { useState } from 'react'
import { useFormState } from 'react-use-form-state'
import tw from 'tailwind.macro'
import Button from '../components/button'
import { ContainerSm } from '../components/container'
import Heading from '../components/heading'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Spinner from '../components/spinner'
import { owl } from '../styles'

const Input = styled.input`
  ${tw`text-base font-sans shadow appearance-none border border-solid border-grey-light rounded w-full py-3 px-4 text-grey-darker leading-tight focus:outline-none focus:shadow-outline`};

  ::placeholder {
    ${tw`text-grey-light`}
  }
`

const Form = styled.form`
  ${owl(tw`mt-8`)}
`

const Label = styled.label`
  ${tw`block text-grey-darker text-sm font-bold mb-2`}
`

const Textarea = styled(Input)`
  height: 250px;
`

const FormText = styled.div`
  ${tw`m-0 text-base font-bold`};
`

const FormErrorText = styled(FormText)`
  ${tw`text-error`}
`

const FormSuccessText = styled(FormText)`
  ${tw`text-success`}
`

const FormFooter = styled.div`
  ${owl(tw`ml-2`)};
  ${tw`flex items-center`};
`

const Contact = ({ data }) => {
  const page = data.page.edges[0].node

  const [formState, { label, text, email, tel, textarea }] = useFormState(
    null,
    {
      withIds: true
    }
  )

  const [submitState, setSubmitState] = useState({
    error: false,
    success: false,
    loading: false
  })

  return (
    <Layout>
      <SEO title={page.suffix} description={page.description} />
      <ContainerSm>
        <Heading level={1}>
          Kostenlose Beratung anfordern und Offerte erhalten
        </Heading>

        <Form
          onSubmit={e => {
            e.preventDefault()
            setSubmitState({ loading: true })
            window
              .fetch('/api/send-mail', {
                method: 'POST',
                body: JSON.stringify(formState.values),
                headers: {
                  'content-type': 'application/json',
                  accept: 'application/json'
                }
              })
              .then(res => {
                setSubmitState({ loading: false })
                if (!res.ok) {
                  setSubmitState({ error: true, status: res.status })
                  return
                }

                setSubmitState({ success: true })
              })
          }}
        >
          <div>
            <Label {...label('company')}>Firma oder Anrede</Label>
            <Input {...text('company')} required placeholder="Firma AG" />
          </div>

          <div>
            <Label {...label('name')}>Vorname und Name</Label>
            <Input {...text('name')} required placeholder="Max Mustermann" />
          </div>

          <div>
            <Label {...label('email')}>E-Mail Adresse</Label>
            <Input
              {...email('email')}
              required
              placeholder="max.mustermann@gmail.com"
            />
          </div>

          <div>
            <Label {...label('phone')}>Telefon</Label>
            <Input {...tel('phone')} required placeholder="079 444 33 22" />
          </div>

          <div>
            <Label {...label('message')}>
              Beschrieb der befallenen Bauteile
            </Label>
            <Textarea as="textarea" {...textarea('message')} required />
          </div>

          <FormFooter>
            <Button disabled={submitState.loading} variant="normal">
              Absenden
            </Button>
            <div>
              {submitState.loading && <Spinner />}

              {submitState.error && (
                <FormErrorText>
                  Oh nein! Ein Fehler ist aufgetreten. Versuche es später
                  erneut.
                </FormErrorText>
              )}

              {submitState.success && (
                <FormSuccessText>Mail wurde versendet!</FormSuccessText>
              )}
            </div>
          </FormFooter>
        </Form>
      </ContainerSm>
    </Layout>
  )
}

export default Contact

export const query = graphql`
  query ContactPageQuery {
    page: allSanityPage(filter: { path: { eq: "/kontakt" } }) {
      edges {
        node {
          suffix
          description
        }
      }
    }
  }
`
